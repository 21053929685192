/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// @mui material components
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { Card, LinearProgress, Stack } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiProgress from "components/VuiProgress";

// MediaQuery for current screen size info
import useMediaQuery from '@mui/material/useMediaQuery'

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import linearGradient from "assets/theme/functions/linearGradient";

// Vision UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import colors from "assets/theme/base/colors";

// Dashboard layout components
import WelcomeMark from "layouts/analytics/components/WelcomeMark";
import Projects from "layouts/analytics/components/Projects";
import OrderOverview from "layouts/analytics/components/OrderOverview";
import SatisfactionRate from "layouts/analytics/components/SatisfactionRate";
import ReferralTracking from "layouts/analytics/components/ReferralTracking";

// React icons
import { IoIosRocket } from "react-icons/io";
import { IoGlobe } from "react-icons/io5";
import { IoBuild } from "react-icons/io5";
import { IoWallet } from "react-icons/io5";
import { IoDocumentText } from "react-icons/io5";
import { FaShoppingCart } from "react-icons/fa";

// Data
import LineChart from "examples/Charts/LineCharts/LineChart";
import BarChart from "examples/Charts/BarCharts/BarChart";
import DonutChart from "examples/Charts/DonutCharts/DonutChart";
import { lineChartDataDashboard } from "layouts/analytics/data/lineChartData";
import { lineChartOptionsDashboard } from "layouts/analytics/data/lineChartOptions";
import { pieChartDataDashboard } from "layouts/analytics/data/pieChartData";
import { pieChartOptionsDashboard2 } from "layouts/analytics/data/pieChartOptions2";
import { barChartDataDashboard } from "layouts/analytics/data/barChartData";
import { barChartOptionsDashboard } from "layouts/analytics/data/barChartOptions";
import { donutChartDataDashboard } from "layouts/analytics/data/donutChartData";
import { donutChartOptionsDashboard } from "layouts/analytics/data/donutChartOptions";
import { donutChartOptionsDashboard2 } from "layouts/analytics/data/donutChartOptions2";
import { donutChartOptionsDashboard3 } from "layouts/analytics/data/donutChartOptions3";
import { donutChartOptionsDashboard4 } from "layouts/analytics/data/donutChartOptions4";
import { donutChartOptionsDashboard5 } from "layouts/analytics/data/donutChartOptions5";
import PieChart from "examples/Charts/PieCharts/PieChart";
import GaugeComponent from 'react-gauge-component'

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CountUp from 'react-countup';
// import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DirectionsCarFilled from '@mui/icons-material/DirectionsCarFilled'
import DirectionsCarIconOutlined from '@mui/icons-material/DirectionsCarOutlined'
import MeetingRoomIcon from '@mui/icons-material/MeetingRoomOutlined'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import FreeBreakfastOutlinedIcon from '@mui/icons-material/FreeBreakfastOutlined';
import LaptopMacOutlinedIcon from '@mui/icons-material/LaptopMacOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import DirectionsWalkOutlinedIcon from '@mui/icons-material/DirectionsWalkOutlined';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import StraightenIcon from '@mui/icons-material/Straighten';
import './test.css'

function Dashboard() {
  const { gradients } = colors;
  const { cardContent } = gradients;
  const [month, setMonth] = useState(0);

  // Check 27" screen size or not
  const isLargeScreen = useMediaQuery('(min-width: 1903px)');
  const isSmaleScreen = useMediaQuery('(max-width: 600px)');
  const isIpad = useMediaQuery('(max-width: 820px)');

  // Activity metrics
  const [wHours, setWHours] = useState([5.00, 6.10, 6.32])
  const [checkWHours, setCheckWHours] = useState(false)
  const [refueling, setRefueling] = useState([0.83, 0.92, 0.95])
  const [meeting, setMeeting] = useState([0.37, 0.45, 0.70])
  const [onsite, setOnsite] = useState([6.20, 7.47, 7.97])
  const [away, setAway] = useState([0.73, 0.80, 1.08])
  const [total, setTotal] = useState([6.93, 8.27, 9.05])

  // Wellbeing metrics
  const [interactions, setInteractions] = useState([3.12, 4.11, 5.36])
  const [checkInterActions, setCheckInteractions] = useState(false)
  const [streaks, setStreaks] = useState([1.10, 0.92, 0.95])
  const [checkStreak, setCheckStreak] = useState(false)

  // meetings metrics
  const [utilization, setUtilization] = useState([42, 65, 76])
  const [usage, setUsage] = useState([24, 34, 53])

  // access metrics
  const [serviceT, setServiceT] = useState([1.56, 1.65, 2.63])
  const [checkServiceT, setCheckServiceT] = useState(false)
  const [serviceQ, setServiceQ] = useState([2.20, 2.59, 3.21])
  const [checkServiceQ, setCheckServiceQ] = useState(false)
  const [mostCommon, setMostCommon] = useState([2, 3, 3])
  const [offPeakLoad, setOffPeakLoad] = useState([35, 37, 48])
  const [checkOffPeak, setCheckOffPeak] = useState(false)
  const [parkTime, setParkTime] = useState([3.52, 4.10, 4.23])
  const [lastMile, setLastMile] = useState([7.82, 8.30, 8.60])
  const [checkLastMile, setCheckLastMile] = useState(false)
  const [totalMile, setTotalMile] = useState([7.82+3.52, 8.30+4.10, 8.60+4.23])


  // charts data
  const [chart, setChart] = useState([away[month], onsite[month]])

  // Effect to update state2 based on state1
  useEffect(() => {
    setCheckWHours(wHours[month] > wHours[month-1 ])
    setCheckInteractions(interactions[month] > interactions[month-1 ])
    setCheckServiceT(serviceT[month] > serviceT[month-1])
    setCheckServiceQ(serviceQ[month] > serviceQ[month-1])
    setChart([away[month], onsite[month]])
    setCheckStreak(streaks[month] > streaks[month-1])
    setCheckLastMile(parkTime[month] + lastMile[month] > parkTime[month] + lastMile[month-1])
    setCheckOffPeak(offPeakLoad[month] > offPeakLoad[month-1])
    // if (month === 0) {
    //   // setWHours(5.00)
    //   setRefueling(0.83)
    //   setMeeting(0.37)
    //   setOnsite(6.20)
    //   setAway(0.73)
    //   setTotal(6.93)
    //   setInteractions(3.12)
    //   setStreaks(1.10)
    //   setUtilization(42)
    //   setUsage(24)
    //   setServiceT(1.56)
    //   setServiceQ(2.20)
    //   setMostCommon(2)
    //   setOffPeakLoad(35)
    //   setParkTime(3.52)
    //   setLastMile(7.82)
    // } else if(month === 1){
    //   // setWHours(6.10)
    //   setRefueling(0.92)
    //   setMeeting(0.45)
    //   setOnsite(7.47)
    //   setAway(0.80)
    //   setTotal(8.27)
    //   setInteractions(3.12)
    //   setStreaks(1.10)
    //   setUtilization(65)
    //   setUsage(34)
    //   setServiceT(1.65)
    //   setServiceQ(2.59)
    //   setMostCommon(3)
    //   setOffPeakLoad(37)
    //   setParkTime(4.10)
    //   setLastMile(8.30)
    // } else {
    //   // setWHours(6.32)
    //   setRefueling(0.95)
    //   setMeeting(0.70)
    //   setOnsite(7.97)
    //   setAway(1.08)
    //   setTotal(9.05)
    //   setInteractions(5.36)
    //   setStreaks(0.95)
    //   setUtilization(76)
    //   setUsage(53)
    //   setServiceT(2.63)
    //   setServiceQ(3.21)
    //   setMostCommon(3)
    //   setOffPeakLoad(48)
    //   setParkTime(4.23)
    //   setLastMile(8.60)
    // }
  }, [month]); // Run this effect whenever state1 changes

  return (
    <DashboardLayout>
      <DashboardNavbar month={month} setMonth={setMonth} />
      <VuiBox py={3}>
      <VuiBox mb={3}>
      <Grid container spacing={2} justifyContent="flex-start" marginBottom={0}>
        <Grid item xs={12} sm={6} md={6} data-aos="fade-right">
          <Typography variant="h4" sx={{ mb: 0, color: 'white !important', textShadow: '0px 3px 10px rgba(0, 0, 0, 1)' }}>
            Activity
          </Typography>
          <VuiTypography variant="button" color="white" fontWeight="regular">
            Behavioral analytics at workplace
          </VuiTypography>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={6} sx={{position: 'sticky'}}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', minWidth: 120 }}>
          <FormControl maxWidth="50%">
            <InputLabel id="demo-simple-select-label"></InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={month}
              label="Month"
              sx={{ backgroundColor: 'transparent !important', color: 'white !important' }}
              onChange={(e) => setMonth(e.target.value)}
            >
              <MenuItem value={0}>July 2023</MenuItem>
              <MenuItem value={1}>Aug 2023</MenuItem>
              <MenuItem value={2}>Sep 2023</MenuItem>
            </Select>
          </FormControl>
        </Box>
        </Grid> */}
        </Grid>
      </VuiBox>

      <VuiBox mb={3}>
      <Grid container spacing={2} justifyContent="flex-start" marginBottom={3}>
        <Grid item xs={12} sm={12} md={4} sx={{transition: 'height 2s ease-in-out'}}>
          <Card sx={{borderRadius: 4, transition: 'height 2s ease-in-out', boxShadow: '0px 0px 10px rgba(0, 0, 0, 1)'}} data-aos="fade-right">
            <VuiBox sx={{ height: "340px" }}>
            <LaptopMacOutlinedIcon 
                  color="white"
                  fontSize="60px"
                  sx={{position: 'absolute', right: -30, bottom: -50, fontSize: "280px", color: 'orange', zIndex: -1, opacity: 0.03, marginTop: 'auto'}}
            />
              <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                Creating something new!
              </VuiTypography>
              <VuiBox display="block" alignItems="center" mb="5px">
                <VuiTypography variant="button" color="text" fontWeight="regular">
                    Average time behind the screen or at work desk.
                </VuiTypography> <br />
              </VuiBox>
              <VuiBox sx={{paddingRight: 0, paddingLeft: 0, marginTop: isLargeScreen ? 0 : 5, display: 'flex', justifyContent: 'center', width: isSmaleScreen ? '80%' : '100%', overflow: 'hidden' }}>
                <GaugeComponent
                  width="80px"
                  height="80px"
                  labels={{
                    valueLabel: {
                      formatTextValue: (value) => value + value > 1 ? value + " hrs" : value + " hr",
                    } 
                  }}
                  arc={{
                    subArcs: [
                      {
                        limit: 2,
                        color: '#EA4228',
                        showTick: true,
                        tooltip: {
                          text: "Low"
                        }
                      },
                      {
                        limit: 4,
                        color: '#F58B19',
                        showTick: true,
                        tooltip: {
                          text: "Medium"
                        }
                      },
                      {
                        limit: 6,
                        color: '#F5CD19',
                        showTick: true,
                        tooltip: {
                          text: "High",
                        }
                      },
                      {
                        limit: 8,
                        color: '#5BE12C',
                        showTick: true,
                        tooltip: {
                          text: "Extreme"
                        }
                      },
                    ]
                  }}
                  value={wHours[month]}
                  maxValue={8}
                />

              </VuiBox>
              <VuiBox sx={{marginTop: 0, textAlign: 'center'}}>
              { month !== 0 && <p data-aos="fade-up" data-aos-once={false}><VuiTypography variant="body2" fontWeight="regular" color={checkWHours ? "success" : "error"} >
                  {checkWHours ? "+":"-"}
                  {checkWHours ? 
                      <CountUp
                      start={0}
                    end={((wHours[month] - wHours[month-1])/wHours[month-1]*100).toFixed(2)}
                    duration={0.5}
                    separator=" "
                    decimals={2}
                    color="white"
                    />
                  :((wHours[month] - wHours[month-1])/wHours[month-1]*100).toFixed(2) + "% less " }
                  {checkWHours ? " % more " : "% less "}
                  <VuiTypography color="text" marginLeft="3px" display="inline">
                    {" than last month"}
                  </VuiTypography>
                </VuiTypography>
                </p>
                }
                </VuiBox>
            </VuiBox>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={8} data-aos="fade-left">
          <Card sx={{borderRadius: 4, boxShadow: '0px 0px 10px rgba(0, 0, 0, 1)'}}>
            <VuiBox sx={{ height: isSmaleScreen ? "690px" : "340px" }}>
              <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                Trends at workplace
              </VuiTypography>
              <WorkOutlineOutlinedIcon 
                  color="white"
                  fontSize="60px"
                  sx={{position: 'absolute', right: -50, bottom: -60, fontSize: "280px", color: 'orange', zIndex: -1, opacity: 0.1, marginTop: 'auto'}}
              />
              <Grid container spacing={2} justifyContent="flex-start">
              <Grid item xs={12} sm={12} md={6}>
              <VuiBox sx={{ height: "310px", justifyContent: 'center', alignItems: 'center', textAlign: 'center', mb: 4}}>
              <VuiTypography variant="button" color="text" fontWeight="regular" sx={{width: '100%'}}>
                    Onsite vs Away
              </VuiTypography>
                <DonutChart
                  donutChartData={chart}
                  donutChartOptions={donutChartOptionsDashboard}
                />
              </VuiBox>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
              <VuiBox sx={{ height: "310px", justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
              <VuiTypography variant="button" color="text" fontWeight="regular" sx={{width: '100%',}}>
                    Type of activity
              </VuiTypography>
                <DonutChart
                  donutChartData={[wHours[month], refueling[month], meeting[month]]}
                  donutChartOptions={donutChartOptionsDashboard2}
                />
              </VuiBox>
              </Grid>
            </Grid>
            </VuiBox>
          </Card>
        </Grid>
        </Grid>
      </VuiBox>

      <VuiBox mb={3}>
      <Grid container spacing={2} justifyContent="flex-start" marginBottom={0} marginTop={5}>
        <Grid item xs={12} sm={12} md={6} data-aos="fade-right">
          <Typography variant="h4" sx={{ mb: 0, color: 'white !important', textShadow: '0px 3px 10px rgba(0, 0, 0, 1)' }}>
            Meetings
          </Typography>
          <VuiTypography variant="button" color="white" fontWeight="regular">
              Analytics related to meetings rooms
          </VuiTypography>
        </Grid>
        {!isIpad && <Grid item xs={12} sm={12} md={6} data-aos="fade-left">
          <Typography variant="h4" sx={{ mb: 0, color: 'white !important', textShadow: '0px 3px 10px rgba(0, 0, 0, 1)' }}>
            Employees’ wellbeing 
          </Typography>
          <VuiTypography variant="button" color="white" fontWeight="regular">
              Analytics for daily employees socialization
          </VuiTypography>
        </Grid>}
        </Grid>
      </VuiBox>

      <VuiBox mb={3}>
      <Grid container spacing={2} justifyContent="flex-start" marginBottom={3}>
        <Grid item xs={12} sm={12} md={12} lg={6} data-aos="fade-up">
          <Card sx={{borderRadius: 4, boxShadow: '0px 0px 10px rgba(0, 0, 0, 1)'}}>
            <VuiBox sx={{ height: "100%" }}>
              <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                Rooms utilization
              </VuiTypography>
              <AccountBalanceOutlinedIcon 
                  color="white"
                  fontSize="60px"
                  sx={{position: 'absolute', right: -60, bottom: -80, fontSize: "280px", color: 'orange', zIndex: -1, opacity: 0.1, marginTop: 'auto'}}
              />
              <Grid container spacing={2} justifyContent="flex-start">
              <Grid item xs={12} sm={12} md={6}>
              <VuiBox sx={{ height: "310px", justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
              <VuiTypography variant="button" color="text" fontWeight="regular" sx={{width: '100%'}}>
                    Capacity utilization
              </VuiTypography>
                <PieChart
                  pieChartData={[utilization[month],100-utilization[month]]}
                  pieChartOptions={donutChartOptionsDashboard3}
                />
              </VuiBox>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
              <VuiBox sx={{ height: "310px", justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
              <VuiTypography variant="button" color="text" fontWeight="regular" sx={{width: '100%'}}>
                    Time utilization
              </VuiTypography>
                <PieChart
                  pieChartData={[usage[month], 100 - usage[month]]}
                  pieChartOptions={pieChartOptionsDashboard2}
                />
              </VuiBox>
              </Grid>
            </Grid>
            </VuiBox>
          </Card>
        </Grid>
        {isIpad && <Grid item xs={12} sm={12} md={12} data-aos="fade-left">
          <Typography variant="h4" sx={{ mb: 0, mt: 5, color: 'white !important', textShadow: '0px 3px 10px rgba(0, 0, 0, 1)' }}>
            Employees’ wellbeing 
          </Typography>
          <VuiTypography variant="button" color="white" fontWeight="regular">
              Analytics for daily employees socialization
          </VuiTypography>
        </Grid>}
        <Grid item xs={12} sm={12} md={6} lg={3} data-aos="fade-up">
          <Card sx={{borderRadius: 4, boxShadow: '0px 0px 10px rgba(0, 0, 0, 1)'}}>
            <VuiBox sx={{ height: "100%" }}>
            <PeopleOutlineIcon 
                  color="white"
                  fontSize="60px"
                  sx={{position: 'absolute', right: -70, bottom: -80, fontSize: "280px", color: 'orange', zIndex: -1, opacity: 0.1, marginTop: 'auto'}}
            />
              <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                Interactions
              </VuiTypography>
              <VuiBox sx={{ height: "310px", justifyContent: 'center', alignItems: 'center'}}>
              <VuiTypography variant="button" color="text" fontWeight="regular" sx={{width: '100%', lineHeight: '0'}}>
                    Number of Conversations with other employees
              </VuiTypography> <br />
              <CountUp
                  start={0}
                  end={interactions[month]}
                  duration={1}
                  separator=" "
                  decimals={2}
                  color="white"
                  style={{color: 'white', fontSize: 72, fontWeight: 'bold', marginLeft: 'auto', position: 'relative', zIndex: 1, top: 30, display: 'flex', justifyContent: 'center'}}
                /> <br />
                <VuiTypography variant="button" color="white" fontWeight="regular" sx={{width: 'auto', position: 'relative', justifyContent: 'center', textAlign: 'center', display: 'flex', marginTop: '0px', color: 'white'}}>
                <Tooltip title="Coversation per employee" arrow>
                    CPE
                </Tooltip>
              </VuiTypography>
              <VuiBox sx={{marginTop: 1, textAlign: 'center', position: 'relative', left: '-5%'}}>
              { month !== 0 && <p data-aos="fade-up" data-aos-once={false}><VuiTypography variant="body2" fontWeight="regular" color={checkInterActions ? "success" : "error"} >
                  {checkInterActions ? "+":"-"}
                  {checkInterActions ? 
                      <CountUp
                      start={0}
                    end={((interactions[month] - interactions[month-1])/interactions[month-1]*100).toFixed(2)}
                    duration={0.5}
                    separator=" "
                    decimals={2}
                    color="white"
                    />
                  :((interactions[month] - interactions[month-1])/interactions[month-1]*100).toFixed(2) + "% less " }
                  {checkInterActions ? " % more " : "% less "}
                  <VuiTypography color="text" marginLeft="3px" display="inline">
                    {" than last month"}
                  </VuiTypography>
                </VuiTypography>
                </p>
                }
                </VuiBox>
              </VuiBox>
            </VuiBox>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={12} md={6} lg={3} data-aos="fade-up">
          <Card sx={{borderRadius: 4, boxShadow: '0px 0px 10px rgba(0, 0, 0, 1)'}}>
            <VuiBox sx={{ height: "100%" }}>
              <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                Streaks
              </VuiTypography>
              <VuiBox sx={{ height: "310px", justifyContent: 'center', alignItems: 'center'}}>
              <FreeBreakfastOutlinedIcon 
                  color="white"
                  fontSize="60px"
                  sx={{position: 'absolute', right: -30, bottom: -110, fontSize: "280px", color: 'orange', zIndex: -1, opacity: 0.1, marginTop: 'auto'}}
              />
              <VuiTypography variant="button" color="text" fontWeight="regular" sx={{width: '100%'}}>
                Time between two successive breaks
              </VuiTypography>
              <VuiBox sx={{marginTop: 5}}>
              <GaugeComponent

                  labels={{
                    valueLabel: {
                      formatTextValue: (value) => value > 1 ? value + " hrs": Math.round(value*60) + ' mins',
                    } 
                  }}
                  arc={{
                    subArcs: [
                      {
                        limit: 0.5,
                        color: '#EA4228',
                        showTick: true
                      },
                      {
                        limit: 1,
                        color: '#F58B19',
                        showTick: true
                      },
                      {
                        limit: 1.5,
                        color: '#F5CD19',
                        showTick: true
                      },
                      {
                        limit: 2,
                        color: '#5BE12C',
                        showTick: true
                      },
                    ]
                  }}
                  value={streaks[month]}
                  maxValue={2}
                />
                </VuiBox>
              <VuiBox sx={{marginTop: 0, textAlign: 'center'}}>
              { month !== 0 && <p data-aos="fade-up" data-aos-once={false}><VuiTypography variant="body2" fontWeight="regular" color={checkStreak ? "success" : "error"} >
                  {checkStreak ? "+":""}
                      <CountUp
                      start={0}
                    end={((streaks[month] - streaks[month-1])/streaks[month-1]*100).toFixed(2)}
                    duration={0.5}
                    separator=" "
                    decimals={2}
                    color="white"
                    />
                  {checkStreak ? " % more " : "% less "}
                  <VuiTypography color="text" marginLeft="3px" display="inline">
                    {" than last month"}
                  </VuiTypography>
                </VuiTypography>
                </p>
                }
                </VuiBox>
              </VuiBox>
            </VuiBox>
          </Card>
        </Grid>


        </Grid>
      </VuiBox>

      <VuiBox mb={3}>
      <Grid container spacing={2} justifyContent="flex-end" marginTop={5}>
        <Grid item xs={12} sm={12} md={12} data-aos="fade-right">
          <Typography variant="h5" sx={{ mb: 0, color: 'white !important', textShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)' }}>
            Access
          </Typography>
          <VuiTypography variant="button" color="white" fontWeight="regular">
              Analytics related to accessibility performance
          </VuiTypography>
        </Grid>
        </Grid>
      </VuiBox>

      <VuiBox mb={3}>
      <Grid container spacing={2} >
      <Grid item xs={12} sm={12} md={12} lg={3}>
      <Grid container spacing={2} justifyContent="flex-start" marginBottom={3}>
        <Grid item xs={12} sm={12} md={12} data-aos="fade-up">
        <Card sx={{borderRadius: 4, boxShadow: '0px 0px 10px rgba(0, 0, 0, 1)'}}>
            <VuiBox sx={{ height: "100%" }}>
            <StraightenIcon 
                  color="white"
                  fontSize="60px"
                  sx={{position: 'absolute', right: -30, bottom: -70, fontSize: "200px", color: 'orange', zIndex: -1, opacity: 0.1, marginTop: 'auto'}}
              />
              <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                Last-mile duration
              </VuiTypography>
              { month !== 0 && " " &&<VuiTypography variant="button" color={checkLastMile ? "success" : "error"} fontWeight="bold" width="100%" marginLeft="10px">
                  {checkLastMile ? "+" + ((totalMile[month] - totalMile[month-1])/totalMile[month-1]*100).toFixed(2) + " %" 
                  :((totalMile[month] - totalMile[month-1])/totalMile[month-1]*100).toFixed(2) + " %" }
                </VuiTypography>
                }
              <VuiBox sx={{ height: "119px", justifyContent: 'center', alignItems: 'center'}}>
              <VuiTypography variant="button" color="text" fontWeight="regular" sx={{width: '100%'}}>
                    Total time from parking to office 
              </VuiTypography>
              <VuiBox sx={{height: '90%', alignItems: 'center', marginTop: '5px', textAlign: 'center'}}>
              <CountUp
                  start={0}
                  end={parkTime[month] + lastMile[month]}
                  duration={1}
                  separator=" "
                  color="white"
                  decimals={2}
                  style={{color: 'white', fontSize: 72, fontWeight: 'bold', marginLeft: 'auto', position: 'relative', zIndex: 1}}
                />
                <VuiTypography variant="button" color="text" fontWeight="regular" sx={{width: 'auto', padding: 1, marginTop: 'auto', color: 'white', fontWeight: 'bold'}}>
                      min
                </VuiTypography>
              </VuiBox>
              </VuiBox>
            </VuiBox>
        </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} data-aos="fade-up">
        <Card sx={{borderRadius: 4, boxShadow: '0px 0px 10px rgba(0, 0, 0, 1)'}}>
            <VuiBox sx={{ height: "100%" }}>
              <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                Morning rush
              </VuiTypography>
              <VuiBox sx={{ height: "270px", justifyContent: 'center', alignItems: 'center'}}>
              <DirectionsWalkOutlinedIcon 
                  color="white"
                  fontSize="60px"
                  sx={{position: 'absolute', right: -70, bottom: -70, fontSize: "280px", color: 'orange', zIndex: -1, opacity: 0.1, marginTop: 'auto'}}
              />
              <VuiTypography variant="button" color="text" fontWeight="regular" sx={{width: '100%'}}>
                    Time from parking to office 
              </VuiTypography>
              <VuiBox sx={{height: '95%', alignItems: 'center', marginTop: '20px'}}>
              <DonutChart
                  donutChartData={[parkTime[month], lastMile[month]]}
                  donutChartOptions={donutChartOptionsDashboard5}
              />
              </VuiBox>
              </VuiBox>
            </VuiBox>
        </Card>
        </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={6} sx={{height: '100%'}}>
        <Grid container spacing={2} justifyContent="between" marginBottom={3}>
          <Grid item xs={12} sm={12} md={12} data-aos="fade-up">
            <Card sx={{borderRadius: 4, boxShadow: '0px 0px 10px rgba(0, 0, 0, 1)'}}>
              <VuiBox sx={{ height: "100%" }}>
              <Grid container spacing={2} justifyContent="flex-start" alignItems={"center"} marginBottom={3}>
              <Grid item xs={12} sm={8} md={8}>
                <AccessTimeIcon 
                  color="white"
                  fontSize="60px"
                  sx={{position: 'absolute', right: -60, bottom: -60, fontSize: "220px", color: 'orange', zIndex: -1, opacity: 0.1, marginTop: 'auto'}}
                />
                <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                  Service time {" "}
                </VuiTypography>
                { month !== 0 && " " &&<VuiTypography variant="button" color={checkServiceT ? "success" : "error"} fontWeight="bold" width="100%" marginLeft="10px">
                  {checkServiceT ? "+" + ((serviceT[month] - serviceT[month-1])/serviceT[month-1]*100).toFixed(2) + " %" 
                  :((serviceT[month] - serviceT[month-1])/serviceT[month-1]*100).toFixed(2) + " %" }
                </VuiTypography>
                }
                <br />
                <VuiTypography variant="button" color="text" fontWeight="regular" sx={{width: '100%'}}>
                      Time needed to pass through gate
                </VuiTypography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                <VuiBox sx={{ height: "100px", display: 'flex', alignItems: 'center'}}>
                <CountUp
                  start={0}
                  end={serviceT[month]}
                  duration={1}
                  separator=" "
                  color="white"
                  decimals={2}
                  style={{color: 'white', fontSize: 72, fontWeight: 'bold', marginLeft: 'auto', position: 'relative', zIndex: 1}}
                />
                <VuiTypography variant="button" color="text" fontWeight="regular" sx={{width: 'auto', padding: 1, marginTop: 'auto', color: 'white', fontWeight: 'bold'}}>
                      min
                </VuiTypography>
                </VuiBox>
                </Grid>
                </Grid>
              </VuiBox>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={12} data-aos="fade-up">
            <Card sx={{borderRadius: 4, boxShadow: '0px 0px 10px rgba(0, 0, 0, 1)'}}>
              <VuiBox sx={{ height: "100%" }}>
              <Grid container spacing={2} justifyContent="flex-start" alignItems={"center"} marginBottom={3}>
                <Grid item xs={12} sm={7} md={8}>
              <DirectionsCarIconOutlined 
                  color="white"
                  fontSize="60px"
                  sx={{position: 'absolute', right: -60, bottom: -60, fontSize: "250px", color: 'orange', zIndex: -1, opacity: 0.1, marginTop: 'auto'}}
                />
                <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                  Service queue
                </VuiTypography>  
                { month !== 0 && " " &&<VuiTypography variant="button" color={checkServiceQ ? "success" : "error"} fontWeight="bold" width="100%" marginLeft="10px">
                  {checkServiceQ ? "+" + ((serviceQ[month] - serviceQ[month-1])/serviceQ[month-1]*100).toFixed(2) + " % " 
                  :((serviceQ[month] - serviceQ[month-1])/serviceQ[month-1]*100).toFixed(2) + " %" }
                  {/* <VuiTypography variant="button" color="text" fontWeight="regular">
                    than last month
                  </VuiTypography> */}
                </VuiTypography>
                }
                <br />
                <VuiTypography variant="button" color="text" fontWeight="regular" sx={{width: '100%'}}>
                  Average number of vehicles in queue
                </VuiTypography>
                </Grid>
                <Grid item xs={12} sm={5} md={4}>
                <VuiBox sx={{ height: "100px", display: 'flex', alignItems: 'center'}}>
                <CountUp
                  start={0}
                  end={serviceQ[month]}
                  duration={1}
                  separator=" "
                  decimals={2}
                  color="white"
                  style={{color: 'white', fontSize: 72, fontWeight: 'bold', marginLeft: 'auto', paddingRight: 0}}
                />
                {!isSmaleScreen && <VuiTypography variant="button" color="text" fontWeight="regular" sx={{width: 'auto', padding: 1, marginTop: 'auto', color: 'white', fontWeight: 'bold'}}>
                      car
                </VuiTypography>}
                </VuiBox>
                </Grid>
                </Grid>
              </VuiBox>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={12} data-aos="fade-up">
            <Card sx={{borderRadius: 4, boxShadow: '0px 0px 10px rgba(0, 0, 0, 1)'}}>
              <VuiBox sx={{ height: "100%" }}>
              <Grid container spacing={2} justifyContent="flex-start" alignItems={"center"} marginBottom={3}>
              <Grid item xs={12} sm={8} md={8}>
              <MeetingRoomIcon 
                  color="white"
                  fontSize="60px"
                  sx={{position: 'absolute', right: -50, bottom: -60, fontSize: "210px", color: 'orange', zIndex: -1, opacity: 0.1, marginTop: 'auto'}}
                />
                <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                  Most common gate
                </VuiTypography>  <br />
                <VuiTypography variant="button" color="text" fontWeight="regular" sx={{width: '100%'}}>
                gate with largest total vehicles served
                </VuiTypography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                <VuiBox sx={{ height: "100px", display: 'flex', alignItems: 'center'}}>
                <CountUp
                  start={0}
                  end={mostCommon[month]}
                  duration={1}
                  separator=" "
                  decimals={0}
                  color="white"
                  prefix="#"
                  style={{color: 'white', fontSize: 72, fontWeight: 'bold', marginLeft: 'auto', paddingRight: 40}}
                />
                </VuiBox>
                </Grid>
                </Grid>
              </VuiBox>
            </Card>
          </Grid>
        </Grid>
        {/* end numbers */}
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={3}>
        <Card sx={{borderRadius: 4, boxShadow: '0px 0px 10px rgba(0, 0, 0, 1)'}}>
            <VuiBox sx={{ height: "100%" }}>
              <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                Off-peak load
              </VuiTypography>
              <VuiBox sx={{ height: "476px", justifyContent: 'center', alignItems: 'center'}}>
              <HourglassEmptyIcon 
                  color="white"
                  fontSize="60px"
                  sx={{position: 'absolute', right: -70, bottom: -70, fontSize: "280px", color: 'orange', zIndex: -1, opacity: 0.1, marginTop: 'auto'}}
              />
              
              <VuiTypography variant="button" color="text" fontWeight="regular" sx={{width: '100%'}}>
                    Ratio of rush-hour served / total served cars.
              </VuiTypography>
              <VuiBox sx={{marginTop: isIpad ? '10px' : '100px'}}>
              <GaugeComponent
                  arc={{
                    subArcs: [
                      {
                        limit: 20,
                        color: '#5BE12C',
                        showTick: true
                      },
                      {
                        limit: 50,
                        color: '#F5CD19',
                        showTick: true
                      },
                      {
                        limit: 80,
                        color: '#F58B19',
                        showTick: true
                      },
                      {
                        limit: 100,
                        color: '#EA4228',
                        showTick: true
                      },
                    ]
                  }}
                  value={offPeakLoad[month]}
                  maxValue={100}
                />
                <VuiBox sx={{marginTop: 0, textAlign: 'center'}}>
              { month !== 0 && <p data-aos="fade-up" data-aos-once={false}><VuiTypography variant="body2" fontWeight="regular" color={checkOffPeak ? "success" : "error"} >
                  {checkOffPeak ? "+":""}
                      <CountUp
                      start={0}
                    end={((offPeakLoad[month] - offPeakLoad[month-1])/offPeakLoad[month-1]*100).toFixed(2)}
                    duration={0.5}
                    separator=" "
                    decimals={2}
                    color="white"
                    />
                  {checkOffPeak ? " % more " : "% less "}
                  <VuiTypography color="text" marginLeft="3px" display="inline">
                    {" than last month"}
                  </VuiTypography>
                </VuiTypography>
                </p>
                }
                </VuiBox>
                </VuiBox>
              </VuiBox>
            </VuiBox>
          </Card>
      </Grid>
      
      </Grid>
      
      </VuiBox>
      
      </VuiBox> 
      
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
