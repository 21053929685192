/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

export const donutChartOptionsDashboard2 = {
    
  chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      theme: "dark",
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        // Customize the tooltip text here
         
        return `<div style="padding: 5px; font-size: 12px; word-spacing: 1px;">
        ${w.globals.seriesNames[seriesIndex] + " "} ${series[seriesIndex]} ${series[seriesIndex] > 1 ? ' hrs': ' hr'}
        <div style="font-size: 10px; color: #a0aec0;">
        ${seriesIndex !== 0 ? seriesIndex === 1 ? 'Coffee & tea break or washroom.' : 'In meeting or huddle rooms.' : "Behind the screen or at work desk."}
        </div>
        </div>`;
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(2) + " %"
      },
    },
    stroke: {
      curve: "smooth",
    },
    legend: {
      show: false,
    },
  //   fill: {
  //     type: "gradient",
  //     gradient: {
  //       shade: "dark",
  //       type: "vertical",
  //       shadeIntensity: 0,
  //       gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
  //       inverseColors: true,
  //       opacityFrom: 0.8,
  //       opacityTo: 0,
  //       stops: [],
  //     },
  //     colors: ["#0075FF", "#2CD9FF"],
  //   },
    fill: {
      // type: "gradient",
      // gradient: {
      //   shade: "dark",
      //   type: "vertical",
      //   shadeIntensity: 0,
      //   gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
      //   inverseColors: false,
      //   opacityFrom: 1,
      //   opacityTo: 0.8,
      //   stops: [],
      // },
      colors: ["#DB710E", "#EBBA18", "#c0392b"],
    },
    stroke: {
      width: 0,
    },
    colors: ["#DB710E", "#EBBA18", "#c0392b"],    
  // colors: ["#d35400", "#c0392b"],  
  legend: {
      show: true,
      position: "bottom", // You can also use "bottom", "right", "left"
      labels: {
          colors: 'white'
      }
    },
    labels: ['Working', 'Refueling', 'Meeting'],
};
